fc.applyAll = applyAll;

// Create an object that has the given prototype.
// Just like Object.create
function createObject(proto) {
	var f = function () {};
	f.prototype = proto;
	return new f();
}

// Copies specifically-owned (non-protoype) properties of `b` onto `a`.
// FYI, $.extend would copy *all* properties of `b` onto `a`.
function extend(a, b) {
	for (var i in b) {
		if (b.hasOwnProperty(i)) {
			a[i] = b[i];
		}
	}
}

/* Date
-----------------------------------------------------------------------------*/

var dayIDs = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

// diffs the two moments into a Duration where full-days are recorded first,
// then the remaining time.
function dayishDiff(d1, d0) {
	return moment.duration({
		days: d1.clone().stripTime().diff(d0.clone().stripTime(), 'days'),
		ms: d1.time() - d0.time(),
	});
}

function isNativeDate(input) {
	return (
		Object.prototype.toString.call(input) === '[object Date]' ||
		input instanceof Date
	);
}

/* Event Element Binding
-----------------------------------------------------------------------------*/

function lazySegBind(container, segs, bindHandlers) {
	container.unbind('mouseover').mouseover(function (ev) {
		var parent = ev.target,
			e,
			i,
			seg;
		while (parent != this) {
			e = parent;
			parent = parent.parentNode;
		}
		if ((i = e._fci) !== undefined) {
			e._fci = undefined;
			seg = segs[i];
			bindHandlers(seg.event, seg.element, seg);
			$(ev.target).trigger(ev);
		}
		ev.stopPropagation();
	});
}

/* Element Dimensions
-----------------------------------------------------------------------------*/

function setOuterWidth(element, width, includeMargins) {
	for (var i = 0, e; i < element.length; i++) {
		e = $(element[i]);
		e.width(Math.max(0, width - hsides(e, includeMargins)));
	}
}

function setOuterHeight(element, height, includeMargins) {
	for (var i = 0, e; i < element.length; i++) {
		e = $(element[i]);
		e.height(Math.max(0, height - vsides(e, includeMargins)));
	}
}

function hsides(element, includeMargins) {
	return (
		hpadding(element) +
		hborders(element) +
		(includeMargins ? hmargins(element) : 0)
	);
}

function hpadding(element) {
	return (
		(parseFloat($.css(element[0], 'paddingLeft', true)) || 0) +
		(parseFloat($.css(element[0], 'paddingRight', true)) || 0)
	);
}

function hmargins(element) {
	return (
		(parseFloat($.css(element[0], 'marginLeft', true)) || 0) +
		(parseFloat($.css(element[0], 'marginRight', true)) || 0)
	);
}

function hborders(element) {
	return (
		(parseFloat($.css(element[0], 'borderLeftWidth', true)) || 0) +
		(parseFloat($.css(element[0], 'borderRightWidth', true)) || 0)
	);
}

function vsides(element, includeMargins) {
	return (
		vpadding(element) +
		vborders(element) +
		(includeMargins ? vmargins(element) : 0)
	);
}

function vpadding(element) {
	return (
		(parseFloat($.css(element[0], 'paddingTop', true)) || 0) +
		(parseFloat($.css(element[0], 'paddingBottom', true)) || 0)
	);
}

function vmargins(element) {
	return (
		(parseFloat($.css(element[0], 'marginTop', true)) || 0) +
		(parseFloat($.css(element[0], 'marginBottom', true)) || 0)
	);
}

function vborders(element) {
	return (
		(parseFloat($.css(element[0], 'borderTopWidth', true)) || 0) +
		(parseFloat($.css(element[0], 'borderBottomWidth', true)) || 0)
	);
}

/* Misc Utils
-----------------------------------------------------------------------------*/

//TODO: arraySlice
//TODO: isFunction, grep ?

function noop() {}

function dateCompare(a, b) {
	// works with moments too
	return a - b;
}

function arrayMax(a) {
	return Math.max.apply(Math, a);
}

function smartProperty(obj, name) {
	// get a camel-cased/namespaced property of an object
	obj = obj || {};
	if (obj[name] !== undefined) {
		return obj[name];
	}
	var parts = name.split(/(?=[A-Z])/),
		i = parts.length - 1,
		res;
	for (; i >= 0; i--) {
		res = obj[parts[i].toLowerCase()];
		if (res !== undefined) {
			return res;
		}
	}
	return obj['default'];
}

function titleEscape(s, event) {
	if (typeof s === 'number') {
		return s;
	}

	//the below strip function now lives in common-services utilities.htmlEscape to apply in the source before applying the html/css
	// we'll be pre-stripping field values in the source service so we can let that flow through here, currently only enabled for SF

	if (
		event &&
		((event.sourceTypeID && event.sourceTypeID === 4) ||
			(event.shareSourceTypeID && event.shareSourceTypeID === 4))
	) {
		s = s.replace(/\n/g, '<br />');

		//cleaning up salesforce returns and elements
		//should probeblay be somewhere else once we extend beyond SF
		s = s.split('\n<');
		s = s.length === 1 ? s[0] : s.join('</br ><');
		s = s.split('>\n');
		s = s.length === 1 ? s[0] : s.join('></br >');

		return s;
	} else {
		//Edit this to strip everything but what is inside a span tag with data-dbk-css in it. So that would leave the style and class tags inside the span
		return s;
	}
}
function htmlEscape(s, event) {
	if (typeof s === 'number') {
		return s;
	}

	//the below strip function now lives in common-services utilities.htmlEscape to apply in the source before applying the html/css
	// we'll be pre-stripping field values in the source service so we can let that flow through here, currently only enabled for SF

	if (
		event &&
		((event.sourceTypeID && event.sourceTypeID === 4) ||
			(event.shareSourceTypeID && event.shareSourceTypeID === 4))
	) {
		s = s.replace(/\n/g, '<br />');

		//cleaning up salesforce returns and elements
		//should probeblay be somewhere else once we extend beyond SF
		s = s.split('\n<');
		s = s.length === 1 ? s[0] : s.join('</br ><');
		s = s.split('>\n');
		s = s.length === 1 ? s[0] : s.join('></br >');

		return s;
	} else {
		//Edit this to strip everything but what is inside a span tag with data-dbk-css in it. So that would leave the style and class tags inside the span
		return strip(s);
	}

	function strip(value) {
		value = value + ''; //Coerce to a string;

		var customElement = 'dbk-css'; //The custom element that we use to identify custom styles <dbk-css></dbk-css>

		var subWrapper = '!~!';
		var cssSubToken = 'CSSSUB';
		var cssSubEndToken = 'CSSSUBEND';

		//Get all matching elements
		var matchingElementsRegex = new RegExp(
			'<' + customElement + '\\s+.*?>',
			'gi'
		);
		var matchingElements = value.match(matchingElementsRegex);

		var endElementRegex;
		var endSubRegex;
		var i;

		if (matchingElements && matchingElements.length) {
			endElementRegex = new RegExp('</' + customElement + '>', 'gi');
			endSubRegex = new RegExp(
				subWrapper + cssSubEndToken + subWrapper,
				'g'
			);

			//Loop through matching elements and substitute for temporary tokens
			for (i = 0; i < matchingElements.length; i++) {
				value = value.replace(
					matchingElements[i],
					subWrapper + cssSubToken + i + subWrapper
				);
			}
			value = value.replace(
				endElementRegex,
				subWrapper + cssSubEndToken + subWrapper
			);
			//Replace html elements in string
			value = replaceHTMLElements(value);

			//Loop through matching spans again and replace temp tokens with scrubbed span elements
			for (i = 0; i < matchingElements.length; i++) {
				value = value.replace(
					subWrapper + cssSubToken + i + subWrapper,
					matchingElements[i]
				);
			}
			return value.replace(endSubRegex, '</' + customElement + '>');
		} else {
			return replaceHTMLElements(value);
		}
	}
}

function replaceHTMLElements(text) {
	return (text + '')
		.replace(/&/g, '&amp;')
		.replace(/(<([^>]+)>)/gi, '') //Replace html tags
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/'/g, '&#039;')
		.replace(/"/g, '&quot;')
		.replace(/\n/g, '<br />');
}

function stripHTMLEntities(text) {
	return text.replace(/&.*?;/g, '');
}

function strpHTMLTags(text, removeLineBreaks) {
	var strippedText = (text + '')
		.replace(/<\/p>/g, '\n')
		.replace(/(<br\ ?\/?>)+/g, '\n')
		.replace(/<\/?[^>]+(>|$)/g, '');

	if (removeLineBreaks) {
		strippedText = strippedText.replace(/\r?\n|\r/g, ' ');
	}
	return strippedText;
}

fc.strpHTMLTags = strpHTMLTags; // expose

function disableTextSelection(element) {
	element
		.attr('unselectable', 'on')
		.css('MozUserSelect', 'none')
		.bind('selectstart.ui', function () {
			return false;
		});
}

/*
function enableTextSelection(element) {
	element
		.attr('unselectable', 'off')
		.css('MozUserSelect', '')
		.unbind('selectstart.ui');
}
*/

function markFirstLast(e) {
	// TODO: use CSS selectors instead
	e.children()
		.removeClass('fc-first fc-last')
		.filter(':first-child')
		.addClass('fc-first')
		.end()
		.filter(':last-child')
		.addClass('fc-last');
}

function getSkinCss(event, opt) {
	var source = event.source || {};
	var eventColor = event.color;
	var sourceColor = source.color;
	var optionColor = opt('eventColor');
	var backgroundColor =
		event.backgroundColor ||
		eventColor ||
		source.backgroundColor ||
		sourceColor ||
		opt('eventBackgroundColor') ||
		optionColor;
	var borderColor =
		event.borderColor ||
		eventColor ||
		source.borderColor ||
		sourceColor ||
		opt('eventBorderColor') ||
		optionColor;
	var textColor =
		event.textColor || source.textColor || opt('eventTextColor');
	var statements = [];
	if (backgroundColor) {
		statements.push('background-color:' + backgroundColor);
	}
	if (borderColor) {
		statements.push('border-color:' + borderColor);
	}
	if (textColor) {
		statements.push('color:' + textColor);
	}
	return statements.join(';');
}

function applyAll(functions, thisObj, args) {
	if ($.isFunction(functions)) {
		functions = [functions];
	}
	if (functions) {
		var i;
		var ret;
		for (i = 0; i < functions.length; i++) {
			ret = functions[i].apply(thisObj, args) || ret;
		}
		return ret;
	}
}

function firstDefined() {
	for (var i = 0; i < arguments.length; i++) {
		if (arguments[i] !== undefined) {
			return arguments[i];
		}
	}
}

function firstFalse() {
	for (var i = 0; i < arguments.length; i++) {
		if (arguments[i] === false) {
			return arguments[i];
		}
	}
	return true;
}

var ambigDateOfMonthRegex = /^\s*\d{4}-\d\d$/;
var ambigTimeOrZoneRegex =
	/^\s*\d{4}-(?:(\d\d-\d\d)|(W\d\d$)|(W\d\d-\d)|(\d\d\d))((T| )(\d\d(:\d\d(:\d\d(\.\d+)?)?)?)?)?$/;

// Creating
// -------------------------------------------------------------------------------------------------

// Creates a new moment, similar to the vanilla moment(...) constructor, but with
// extra features (ambiguous time, enhanced formatting). When gived an existing moment,
// it will function as a clone (and retain the zone of the moment). Anything else will
// result in a moment in the local zone.
fc.moment = function () {
	return makeMoment(arguments);
};

// Sames as fc.moment, but forces the resulting moment to be in the UTC timezone.
fc.moment.utc = function () {
	var mom = makeMoment(arguments, true);

	// Force it into UTC because makeMoment doesn't guarantee it.
	if (mom.hasTime()) {
		// don't give ambiguously-timed moments a UTC zone
		mom.utc();
	}

	return mom;
};

// Same as fc.moment, but when given an ISO8601 string, the timezone offset is preserved.
// ISO8601 strings with no timezone offset will become ambiguously zoned.
fc.moment.parseZone = function () {
	return makeMoment(arguments, true, true);
};

// Builds an FCMoment from args. When given an existing moment, it clones. When given a native
// Date, or called with no arguments (the current time), the resulting moment will be local.
// Anything else needs to be "parsed" (a string or an array), and will be affected by:
//    parseAsUTC - if there is no zone information, should we parse the input in UTC?
//    parseZone - if there is zone information, should we force the zone of the moment?
function makeMoment(args, parseAsUTC, parseZone) {
	var input = args[0];
	var isSingleString = args.length == 1 && typeof input === 'string';
	var isAmbigTime;
	var isAmbigZone;
	var ambigMatch;
	var output; // an object with fields for the new FCMoment object

	if (moment.isMoment(input)) {
		output = moment.apply(null, args); // clone it

		// the ambig properties have not been preserved in the clone, so reassign them
		if (input._ambigTime) {
			output._ambigTime = true;
		}
		if (input._ambigZone) {
			output._ambigZone = true;
		}
	} else if (isNativeDate(input) || input === undefined) {
		output = moment.apply(null, args); // will be local
	} else {
		// "parsing" is required
		isAmbigTime = false;
		isAmbigZone = false;

		if (isSingleString) {
			if (ambigDateOfMonthRegex.test(input)) {
				// accept strings like '2014-05', but convert to the first of the month
				input += '-01';
				args = [input]; // for when we pass it on to moment's constructor
				isAmbigTime = true;
				isAmbigZone = true;
			} else if ((ambigMatch = ambigTimeOrZoneRegex.exec(input))) {
				isAmbigTime = !ambigMatch[5]; // no time part?
				isAmbigZone = true;
			}
		} else if ($.isArray(input)) {
			// arrays have no timezone information, so assume ambiguous zone
			isAmbigZone = true;
		}
		// otherwise, probably a string with a format

		if (parseAsUTC) {
			output = moment.utc.apply(moment, args);
		} else {
			output = moment.apply(null, args);
		}

		if (isAmbigTime) {
			output._ambigTime = true;
			output._ambigZone = true; // ambiguous time always means ambiguous zone
		} else if (parseZone) {
			// let's record the inputted zone somehow
			if (isAmbigZone) {
				output._ambigZone = true;
			} else if (isSingleString) {
				output.zone(input); // if not a valid zone, will assign UTC
			}
		}
	}

	return new FCMoment(output);
}

// Our subclass of Moment.
// Accepts an object with the internal Moment properties that should be copied over to
// `this` object (most likely another Moment object). The values in this data must not
// be referenced by anything else (two moments sharing a Date object for example).
function FCMoment(internalData) {
	extend(this, internalData);
}

//Chain the prototype to Moment's
FCMoment.prototype = createObject(moment.fn);

// We need this because Moment's implementation won't create an FCMoment,
// nor will it copy over the ambig flags.
FCMoment.prototype.clone = function () {
	return makeMoment([this]);
};

// Time-of-day
// -------------------------------------------------------------------------------------------------

// GETTER
// Returns a Duration with the hours/minutes/seconds/ms values of the moment.
// If the moment has an ambiguous time, a duration of 00:00 will be returned.
//
// SETTER
// You can supply a Duration, a Moment, or a Duration-like argument.
// When setting the time, and the moment has an ambiguous time, it then becomes unambiguous.
FCMoment.prototype.time = function (time) {
	if (time == null) {
		// getter
		return moment.duration({
			hours: this.hours(),
			minutes: this.minutes(),
			seconds: this.seconds(),
			milliseconds: this.milliseconds(),
		});
	} else {
		// setter

		delete this._ambigTime; // mark that the moment now has a time

		if (!moment.isDuration(time) && !moment.isMoment(time)) {
			time = moment.duration(time);
		}

		return this.hours(time.hours() + time.days() * 24) // day value will cause overflow (so 24 hours becomes 00:00:00 of next day)
			.minutes(time.minutes())
			.seconds(time.seconds())
			.milliseconds(time.milliseconds());
	}
};

// Converts the moment to UTC, stripping out its time-of-day and timezone offset,
// but preserving its YMD. A moment with a stripped time will display no time
// nor timezone offset when .format() is called.
FCMoment.prototype.stripTime = function () {
	var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array

	// set the internal UTC flag
	// moment.fn.utc.call(this); // call the original method, because we don't want to affect _ambigZone

	this._ambigTime = true;
	this._ambigZone = true; // if ambiguous time, also ambiguous timezone offset

	this.year(a[0])
		.month(a[1])
		.date(a[2])
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0);

	return this; // for chaining
};

// Returns if the moment has a non-ambiguous time (boolean)
FCMoment.prototype.hasTime = function () {
	return !this._ambigTime;
};

// Timezone
// -------------------------------------------------------------------------------------------------

// Converts the moment to UTC, stripping out its timezone offset, but preserving its
// YMD and time-of-day. A moment with a stripped timezone offset will display no
// timezone offset when .format() is called.
FCMoment.prototype.stripZone = function () {
	var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array
	var wasAmbigTime = this._ambigTime;

	// moment.fn.utc.call(this); // set the internal UTC flag

	this.year(a[0]) // TODO: find a way to do this in one shot
		.month(a[1])
		.date(a[2])
		.hours(a[3])
		.minutes(a[4])
		.seconds(a[5])
		.milliseconds(a[6]);

	if (wasAmbigTime) {
		// the above call to .utc()/.zone() unfortunately clears the ambig flags, so reassign
		this._ambigTime = true;
	}

	// Mark the zone as ambiguous. This needs to happen after the .utc() call, which calls .zone(), which
	// clears all ambig flags. Same concept with the .year/month/date calls in the case of moment-timezone.
	this._ambigZone = true;

	return this; // for chaining
};

// Returns of the moment has a non-ambiguous timezone offset (boolean)
FCMoment.prototype.hasZone = function () {
	return !this._ambigZone;
};

// this method implicitly marks a zone
FCMoment.prototype.zone = function (tzo) {
	if (tzo != null) {
		// FYI, the delete statements need to be before the .zone() call or else chaos ensues
		// for reasons I don't understand.
		delete this._ambigTime;
		delete this._ambigZone;
	}

	return moment.fn.zone.apply(this, arguments);
};

// this method implicitly marks a zone
FCMoment.prototype.local = function () {
	var a = this.toArray(); // year,month,date,hours,minutes,seconds as an array
	var wasAmbigZone = this._ambigZone;

	// will happen anyway via .local()/.zone(), but don't want to rely on internal implementation
	delete this._ambigTime;
	delete this._ambigZone;

	moment.fn.local.apply(this, arguments);

	if (wasAmbigZone) {
		// If the moment was ambiguously zoned, the date fields were stored as UTC.
		// We want to preserve these, but in local time.
		this.year(a[0]) // TODO: find a way to do this in one shot
			.month(a[1])
			.date(a[2])
			.hours(a[3])
			.minutes(a[4])
			.seconds(a[5])
			.milliseconds(a[6]);
	}

	return this; // for chaining
};

// this method implicitly marks a zone
FCMoment.prototype.utc = function () {
	// will happen anyway via .local()/.zone(), but don't want to rely on internal implementation
	delete this._ambigTime;
	delete this._ambigZone;

	return moment.fn.utc.apply(this, arguments);
};

// Formatting
// -------------------------------------------------------------------------------------------------

FCMoment.prototype.format = function () {
	if (arguments[0]) {
		return formatDate(this, arguments[0]); // our extended formatting
	}
	if (this._ambigTime) {
		return momentFormat(this, 'YYYY-MM-DD');
	}
	if (this._ambigZone) {
		return momentFormat(this, 'YYYY-MM-DD[T]HH:mm:ss');
	}
	return momentFormat(this); // default moment original formatting
};

FCMoment.prototype.toISOString = function () {
	if (this._ambigTime) {
		return momentFormat(this, 'YYYY-MM-DD');
	}
	if (this._ambigZone) {
		return momentFormat(this, 'YYYY-MM-DD[T]HH:mm:ss');
	}
	return moment.fn.toISOString.apply(this, arguments);
};

// Querying
// -------------------------------------------------------------------------------------------------

// Is the moment within the specified range? `end` is exclusive.
FCMoment.prototype.isWithin = function (start, end) {
	var a = commonlyAmbiguate([this, start, end]);
	return a[0] >= a[1] && a[0] < a[2];
};

// Make these query methods work with ambiguous moments
$.each(['isBefore', 'isAfter', 'isSame'], function (i, methodName) {
	FCMoment.prototype[methodName] = function (input, units) {
		var a = commonlyAmbiguate([this, input]);
		return moment.fn[methodName].call(a[0], a[1], units);
	};
});

// Misc Internals
// -------------------------------------------------------------------------------------------------

// given an array of moment-like inputs, return a parallel array w/ moments similarly ambiguated.
// for example, of one moment has ambig time, but not others, all moments will have their time stripped.
function commonlyAmbiguate(inputs) {
	var outputs = [];
	var anyAmbigTime = false;
	var anyAmbigZone = false;
	var i;

	for (i = 0; i < inputs.length; i++) {
		outputs.push(fc.moment(inputs[i]));
		anyAmbigTime = anyAmbigTime || outputs[i]._ambigTime;
		anyAmbigZone = anyAmbigZone || outputs[i]._ambigZone;
	}

	for (i = 0; i < outputs.length; i++) {
		if (anyAmbigTime) {
			outputs[i].stripTime();
		} else if (anyAmbigZone) {
			outputs[i].stripZone();
		}
	}

	return outputs;
}
